import React from "react";

const CaretIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
    >
      <path
        d="M0.541622 0.81785C0.728403 0.631124 0.9817 0.526227 1.24581 0.526227C1.50992 0.526227 1.76321 0.631124 1.95 0.81785L6.88031 5.74816L11.8106 0.817849C11.9985 0.636415 12.2501 0.536022 12.5112 0.538291C12.7724 0.540561 13.0222 0.645311 13.2069 0.829982C13.3915 1.01465 13.4963 1.26447 13.4985 1.52562C13.5008 1.78678 13.4004 2.03837 13.219 2.22622L7.58449 7.86072C7.39771 8.04745 7.14442 8.15234 6.88031 8.15234C6.6162 8.15234 6.3629 8.04745 6.17612 7.86072L0.541622 2.22622C0.354896 2.03944 0.25 1.78615 0.25 1.52204C0.25 1.25793 0.354896 1.00463 0.541622 0.81785Z"
        fill="#1E2F35"
      />
    </svg>
  );
};

export default CaretIcon;
